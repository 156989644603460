<template>
    <div id="OpexMatrixPlanning">
        <v-container fluid app>
            <ZnapHotGrid
                :options="options"
            >
            </ZnapHotGrid>
        </v-container>
    </div>
</template>

<script>
import Vue from 'vue'
import ZnapHotGrid from '@/components/znap/HotGridMatrix.vue'

export default {
    name:'OpexMatrixPlanning',

    components:{
        ZnapHotGrid
    },

    data: () => ({
        options: [
            {
                column: 'id_event',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Evento *',
                required: true,
                filterable: false,
                endpoint: [ Vue.prototype.$ipEvent, 'event' ]
            },
            {
                column: 'id_purpose',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Propósito *',
                required: true,
                filterable: true,
                endpoint: [ Vue.prototype.$ipOrganization, 'purpose' ],
                conditions: [
                    {
                        AndOr: "AND",
                        column: "id_purpose_type",
                        operator: "=",
                        value: 1 // Despesas
                    },
                    {
                        AndOr: "AND",
                        column: "matrix",
                        operator: "=",
                        value: 1
                    },
                    {
                        AndOr: "AND",
                        column: "id_planning_function",
                        operator: "=",
                        value: null // pegar dinâmico
                    },
                ]
            },
            {
                column: 'id_cost_center_main',
                is: 'ComboBox',
                items: [],
                multiple: true,
                label: 'Centro de custo principal *',
                required: true,
                filterable: true,
                endpoint: [ Vue.prototype.$ipOrganization, 'main-cost-center' ],
                conditions: [
                    {
                        AndOr: "AND",
                        column: "id_purpose",
                        operator: "=",
                        value: null // pegar dinâmico
                    },
                ]
            },
            {
                column: 'id_cost_center_planning',
                is: 'ComboBox',
                items: [],
                multiple: true,
                label: 'Centro de custo',
                required: true,
                filterable: true,
                endpoint: [ Vue.prototype.$ipOrganization, 'purpose-cost-center' ],
                conditions: [
                    {
                        AndOr: "AND",
                        column: "id_purpose",
                        operator: "=",
                        value: null // pegar dinâmico
                    },
                    {
                        AndOr: "AND",
                        column: "id_cost_center_main",
                        operator: "=",
                        value: null // pegar dinâmico
                    },
                ]
            },
            {
                column: 'id_account_group',
                is: 'ComboBox',
                items: [],
                multiple: true,
                label: 'Conta gerencial *',
                required: true,
                filterable: true,
                endpoint: [ Vue.prototype.$ipOrganization, 'purpose-account' ],
                conditions: [
                    {
                        AndOr: "AND",
                        column: "id_purpose",
                        operator: "=",
                        value: null // pegar dinâmico
                    }
                ]
            },
            {
                column: 'id_chart_account',
                is: 'ComboBox',
                items: [],
                multiple: true,
                label: 'Conta contábil *',
                required: true,
                filterable: false,
                endpoint: [ Vue.prototype.$ipAccount, 'chart-account-group' ],
                conditions: [
                    {
                        AndOr: "AND",
                        column: "id_account_group",
                        operator: "=",
                        value: null // pegar dinâmico
                    }
                ]
            },
            {
                column: 'id_sales_channel',
                columnToRemove: 'sales_channel',
                is: 'ComboBox',
                items: [],
                multiple: true,
                label: 'Tipo de contrato',
                required: false,
                filterable: true,
                endpoint: [ Vue.prototype.$ipSales, 'sales-channel', 'list-options' ],
                conditions: [
                    {
                        AndOr: "AND",
                        column: "id_sales_channel_type",
                        operator: "=",
                        value: 1
                    },
                ],
            },
            {
                column: 'id_sales_force',
                columnToRemove: 'sales_force_name',
                is: 'ComboBox',
                items: [],
                multiple: true,
                label: 'Gerente executivo',
                required: false,
                filterable: true,
                endpoint: [ Vue.prototype.$ipSales, 'sales-force', 'list-options' ],
                conditions: [
                    {
                        AndOr: "AND",   
                        column: "id_sales_force_type",
                        operator: "=",
                        value: 6
                    },
                ],
            },
            {
                column: 'id_sales_force_bp',
                columnToRemove: 'sales_force_bp_name',
                is: 'ComboBox',
                items: [],
                multiple: true,
                label: 'BP',
                required: false,
                filterable: true,
                endpoint: [ Vue.prototype.$ipSales, 'sales-force', 'list-options' ],
                conditions: [
                    {
                        AndOr: "AND",   
                        column: "id_sales_force_type",
                        operator: "=",
                        value: 7
                    }
                ],
            },
            {
                column: 'id_customer_group',
                columnToRemove: 'customer_group',
                is: 'ComboBox',
                items: [],
                multiple: true,
                label: 'Conta cliente',
                required: false,
                filterable: false,
                endpoint: [ Vue.prototype.$ipCustomer, 'customer-group', 'list-options' ],
                conditions: [
                    {
                        AndOr: "AND",   
                        column: "id_customer_group_type",
                        operator: "=",
                        value: 1
                    },
                ]
            }
        ]
    }),
}
</script>

<style scoped>
</style>